import { MutationHookOptions, gql, useMutation } from '@apollo/client';

const MUTATION = gql`
  mutation setSettings(
    $maximumAttemptCount: JSON!
    $tentativeClaimTimer: JSON!
    $failureCooldown: JSON!
  ) {
    maximumAttemptCount: setSetting(
      key: "maximumAttemptCount"
      value: $maximumAttemptCount
    ) {
      key
      value
    }
    tentativeClaimTimer: setSetting(
      key: "tentativeClaimTimer"
      value: $tentativeClaimTimer
    ) {
      key
      value
    }
    failureCooldown: setSetting(
      key: "failureCooldown"
      value: $failureCooldown
    ) {
      key
      value
    }
  }
`;

export type Variables = {
  readonly maximumAttemptCount: number;
  readonly tentativeClaimTimer: number;
  readonly failureCooldown: number;
};

export type Response = {
  readonly maximumAttemptCount: {
    readonly key: string;
    readonly value: number;
  };
  readonly tentativeClaimTimer: {
    readonly key: string;
    readonly value: number;
  };
  readonly failureCooldown: {
    readonly key: string;
    readonly value: number;
  };
};

export const useSetSettingsMutation = (
  options: MutationHookOptions<Response, Variables>,
) => useMutation<Response, Variables>(MUTATION, options);
