import { QueryHookOptions, gql, useQuery } from '@apollo/client';

const QUERY = gql`
  query getSettings {
    maximumAttemptCount: setting(key: "maximumAttemptCount")
    tentativeClaimTimer: setting(key: "tentativeClaimTimer")
    failureCooldown: setting(key: "failureCooldown")
  }
`;

export type Variables = {};

export type Response = {
  readonly maximumAttemptCount: number;
  readonly tentativeClaimTimer: number;
  readonly failureCooldown: number;
};

export const useGetSettingsQuery = (
  options: QueryHookOptions<Response, Variables>,
) => useQuery<Response, Variables>(QUERY, options);
