import { gql } from '@apollo/client';

export const QUERY = gql`
  query {
    users {
      id
      email
      firstName
      lastName
      fullName
      active
      dailyClaimLimit
      accessLevel {
        name
        handle
      }
      leadPool {
        id
        name
      }
      leadSources {
        id
      }
    }
  }
`;

export type Variables = {};

export type Response = {
  readonly users: {
    readonly id: string;
    readonly email: string;
    readonly firstName: string;
    readonly lastName: string;
    readonly fullName: string | null;
    readonly active: boolean;
    readonly dailyClaimLimit: number | null;
    readonly accessLevel: {
      readonly name: string;
      readonly handle:
        | 'sales'
        | 'operations'
        | 'operationsAdmin'
        | 'finance'
        | 'admin';
    };
    readonly leadPool: {
      readonly id: string;
      readonly name: string;
    } | null;
    readonly leadSources: {
      readonly id: string;
    }[];
  }[];
};
