import { useMutation, useQuery } from '@apollo/client';
import { useEffect, useMemo, useState } from 'react';
import ErrorMessage from '../../../../components/content/ErrorMessage';
import Button from '../../../../components/controls/Button';
import CheckboxField from '../../../../components/controls/CheckboxField';
import DropdownField from '../../../../components/controls/DropdownField';
import TextField from '../../../../components/controls/TextField';
import Modal from '../../../../components/overlay/Modal';
import { Response as GetUsersResponse } from '../../queries/getUsersQuery';
import {
  MUTATION as DISABLE_LEAD_SOURCE_FOR_AGENT_MUTATION,
  Response as DisableLeadSourceForAgentResponse,
  Variables as DisableLeadSourceForAgentVariables,
} from './mutations/disableLeadSourceForAgentMutation';
import {
  MUTATION as ENABLE_LEAD_SOURCE_FOR_AGENT_MUTATION,
  Response as EnableLeadSourceForAgentResponse,
  Variables as EnableLeadSourceForAgentVariables,
} from './mutations/enableLeadSourceForAgentMutation';
import {
  MUTATION as UPDATE_USER_MUTATION,
  Response as UpdateUserResponse,
  Variables as UpdateUserVariables,
} from './mutations/updateUserMutation';
import {
  QUERY as GET_ENABLED_LEAD_SOURCES_FOR_AGENT_QUERY,
  Response as GetEnabledLeadSourcesForAgentResponse,
  Variables as GetEnabledLeadSourcesForAgentVariables,
} from './queries/getEnabledLeadSourcesForAgentQuery';
import {
  QUERY as GET_LEAD_POOLS_QUERY,
  Response as GetLeadPoolsResponse,
} from './queries/getLeadPoolsQuery';
import { useGetLeadSources } from '../../../../hooks/useGetLeadSources';
import { useGetUserAccessLevels } from '../../../../hooks/useGetUserAccessLevel';
import { useUser } from '../../../../hooks/useUser';

type Props = {
  readonly user: GetUsersResponse['users'][number];
  readonly onClose: () => void;
  readonly onEditSuccess: () => void;
};

export function EditUserModal({ user, onClose, onEditSuccess }: Props) {
  const currentUser = useUser();

  const leadSources = useGetLeadSources();
  const userAccessLevels = useGetUserAccessLevels();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [accessLevel, setAccessLevel] = useState<string>();
  const [active, setActive] = useState(true);
  const [dailyClaimLimit, setDailyClaimLimit] = useState('');
  const [leadPoolId, setLeadPoolId] = useState<string>();

  const {
    data: enabledLeadSourcesForAgent,
    loading: getEnabledLeadSourcesForAgentLoading,
    refetch,
  } = useQuery<
    GetEnabledLeadSourcesForAgentResponse,
    GetEnabledLeadSourcesForAgentVariables
  >(GET_ENABLED_LEAD_SOURCES_FOR_AGENT_QUERY, {
    variables: {
      id: user.id,
    },
  });

  const { data: leadPools } =
    useQuery<GetLeadPoolsResponse>(GET_LEAD_POOLS_QUERY);

  const [updateUser, { error, loading }] = useMutation<
    UpdateUserResponse,
    UpdateUserVariables
  >(UPDATE_USER_MUTATION, {
    onCompleted: () => {
      onEditSuccess();
      onClose();
    },
    onError: (error) => console.error(error),
  });

  const [enableLeadSource] = useMutation<
    EnableLeadSourceForAgentResponse,
    EnableLeadSourceForAgentVariables
  >(ENABLE_LEAD_SOURCE_FOR_AGENT_MUTATION, {
    onCompleted: () => refetch(),
  });

  const [disableLeadSource] = useMutation<
    DisableLeadSourceForAgentResponse,
    DisableLeadSourceForAgentVariables
  >(DISABLE_LEAD_SOURCE_FOR_AGENT_MUTATION, {
    onCompleted: () => refetch(),
  });

  useEffect(() => {
    setFirstName(user.firstName);
    setLastName(user.lastName);
    setAccessLevel(user.accessLevel.handle);
    setActive(user.active);
    setDailyClaimLimit(
      user.dailyClaimLimit ? user.dailyClaimLimit.toString() : '',
    );
    setLeadPoolId(user.leadPool?.id.toString());
  }, [user]);

  const enabledLeadSourceIds = useMemo(() => {
    return (
      enabledLeadSourcesForAgent?.user.leadSources.map(
        (leadSource) => leadSource.id,
      ) ?? []
    );
  }, [enabledLeadSourcesForAgent]);

  const handleUpdateUser = () => {
    updateUser({
      variables: {
        id: user.id,
        model: {
          firstName,
          lastName,
          accessLevel,
          active,
          dailyClaimLimit: dailyClaimLimit ? parseInt(dailyClaimLimit) : null,
          leadPoolId: leadPoolId ?? null,
        },
      },
    });
  };

  const isMe = !!(currentUser && currentUser.id === user.id);

  return (
    <Modal heading={isMe ? 'Edit User (Me)' : 'Edit User'} onClose={onClose}>
      <form
        onSubmit={(event) => {
          event.preventDefault();
          handleUpdateUser();
        }}
      >
        <div className="grid grid-cols-2 gap-4 mb-4">
          <div>
            <TextField
              label="First Name"
              value={firstName}
              onChange={(value) => setFirstName(value)}
              type="text"
              placeholder="First Name"
            />
          </div>
          <div>
            <TextField
              label="Last Name"
              value={lastName}
              onChange={(value) => setLastName(value)}
              type="text"
              placeholder="Last Name"
            />
          </div>
          <div className="col-span-1">
            <TextField
              disabled
              label="Email Address"
              value={user.email}
              type="email"
              placeholder="Email Address"
            />
          </div>
          <div className="col-span-1">
            <TextField
              label="Daily Claim Limit"
              value={dailyClaimLimit}
              type="text"
              placeholder="Daily Claim Limit"
              onChange={(value) => setDailyClaimLimit(value)}
            />
          </div>
          <div className="col-span-2">
            <div className="mb-1">
              <h6 className="text-gray-600 text-sm mb-1 font-medium block">
                Enabled Sources ({enabledLeadSourceIds.length}/
                {leadSources.length})
              </h6>
            </div>

            <div className="h-28 rounded-md overflow-y-scroll border border-gray-400">
              {leadSources.map((source) => (
                <div
                  key={source.id}
                  className="p-1 border-b border-gray-200 last:border-none"
                >
                  <CheckboxField
                    size="sm"
                    disabled={getEnabledLeadSourcesForAgentLoading}
                    checked={enabledLeadSourceIds.includes(source.id)}
                    onChange={(checked) => {
                      const variables:
                        | EnableLeadSourceForAgentVariables
                        | DisableLeadSourceForAgentVariables = {
                        agentId: user.id,
                        leadSourceId: source.id,
                      };

                      if (checked) enableLeadSource({ variables });
                      else disableLeadSource({ variables });
                    }}
                  >
                    {source.name}
                  </CheckboxField>
                </div>
              ))}
            </div>
          </div>
          <div className="col-span-2">
            <DropdownField
              label="Lead Pool"
              options={
                leadPools?.leadPools.map((pool) => ({
                  text: pool.name,
                  value: pool.id.toString(),
                })) ?? []
              }
              value={leadPoolId}
              placeholder="Default"
              onChange={setLeadPoolId}
            />
          </div>
          {!isMe && (
            <>
              <div className="col-span-2">
                <DropdownField
                  label="Access Level"
                  options={userAccessLevels.map((option) => ({
                    text: option.name,
                    value: option.handle,
                  }))}
                  value={accessLevel}
                  onChange={(value) => setAccessLevel(value)}
                />
              </div>
              <div className="col-span-2">
                <CheckboxField
                  checked={active}
                  onChange={(checked) => setActive(checked)}
                >
                  <p>Active</p>
                </CheckboxField>
              </div>
            </>
          )}
        </div>

        {error && <ErrorMessage error={error} />}

        <Button type="submit" text="Save" primary loading={loading} />
      </form>
    </Modal>
  );
}
